<template>
    <div class="upload-container" :class="files.length&&'filled'">
        <input type="file" ref="fileInput" :name="name" :id="name" class="fileUploadbtn transparent" :accept="fileTypes" @change="updateFileList" multiple/>
        <div v-if="!files.length" class="upload-label">
            <FeatherIcon feather-icon="upload" />{{ placeholder }}
        </div>
        <small v-for="f in files" :key="f.name" class="fileName">{{f.name}}</small>
        <Button v-if="files.length && clearable" @click.prevent="clearInput" title="Clear Selected Files" class="input-icon input-clear ghost">
            <FeatherIcon feather-icon="trash-2" />
        </Button>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component';
    import FeatherIcon from './FeatherIcon.vue'
    import Button from './Button.vue'

    @Options({
        name: 'Upload',
        props: {
            name: String,
            placeholder: {
                type: String,
                default: "Upload Files"
            },
            multiple: {
                type: Boolean,
                default: false
            },
            fileTypes: String,
            clearable: Boolean,
        },
        data() {
            return {
                files: {}
            }
        },
        components: {
            FeatherIcon,
            Button
        },
        methods: {
            updateFileList() {
                this.files = event.target.files;
            },
            clearInput() {
                this.files = {};
                $(this.$refs.fileInput).val('');
            }
        }
    })

    export default class Upload extends Vue {}
</script>

<style lang="scss">
    .upload-container {
        display: flex;
        gap:.33rem;
        padding: .75rem;
        flex-direction: column;
        position: relative;
        font-weight: 300;
        justify-content: center;
        align-items: center;

        .upload-label {
            display: flex;
            gap: 1rem;
        }
        svg {
            stroke-width: .66px;
        }

        .fileName {
            text-align: center;
            font-weight: 400;
        }

        input {
            cursor: pointer;
            position: absolute;
            inset: 0;
        }
    }
</style>