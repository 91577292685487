<template>
    <div class="select-container">
        <select :name="name" :id="name" v-model="selectVal" :class="selectVal?'filled':'placeholder-text'" :multiple="multi">
            <option value="" selected disabled>{{label}}</option>
            <slot />
        </select>
        <label v-if="!nolabel" :class="selectVal&&'show'" :for="name">{{label}}</label>
        <Button v-if="this.selectVal && clearable" @click.prevent="clearSelect" title="Clear Selection" class="select-icon select-clear ghost">
            <FeatherIcon feather-icon="x" />
        </Button>
        <Button @click.prevent="" class="select-icon no-touch ghost">
            <FeatherIcon feather-icon="chevron-down" />
        </Button>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import FeatherIcon from './FeatherIcon.vue';
    import Button from './Button.vue';
    import $ from 'jquery'

    @Options({
        props: {
            name: String,
            label: String,
            multi: Boolean,
            hashMe: Boolean,
            clearable: Boolean,
            nolabel: {
                type: Boolean,
                default: false
            }
        },
        components: {
            FeatherIcon,
            Button
        },
        data() {
            return {
                selectVal: '',
                hash: window.location.hash
            }
        },
        mounted() {
            this.setSelect(this.hash);
        },
        watch: {
            selectVal(v) {
                if (this.hashMe) {
                    let newHash = '#' + (v &&$(`option[value="${v}"]`).prop('id'));

                    if (history.replaceState) {
                        history.replaceState(null, null, newHash);
                    } else {
                        location.hash = newHash;
                    };
                };
            }
        },
        methods: {
            clearSelect() {
                setTimeout(() => {
                    this.selectVal = '';
                }, 300);
            },
            setSelect(optId) {
                optId && (this.selectVal = $(`option${optId}`).val());
            }
        }
    })

    export default class Select extends Vue {}
</script>

<style lang="scss">
    .select-icon {
        position: absolute;
        right: 2px;
        top: 2px;
        height: calc(100% - 4px);
        display: flex;
        align-items: center;
    }

    .select-clear {
        cursor: pointer;
        right: clamp(1.25rem, 4vw, 2rem);
    }

    select:focus {
        ~.select-icon svg {
            stroke-width: 2.5px;
        }
    }
</style>