<template>
    <main>
        <section>
            <h1 class="center-align">Send Us a Message</h1>
            <form
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                route="contact-thanks">
                <input type="hidden" name="bot-field" class="hidden" />
                <input type="hidden" name="form-name" class="hidden" value="contact" />
                <Input name="First" label="First Name" :input-regex="regex.nameRegex" required />
                <Input name="Last" label="Last Name" :input-regex="regex.nameRegex" required />
                <Input type="tel" name="Phone" label="Phone Number" :input-regex="regex.phoneRegex" required />
                <Input type="email" name="userEmail" label="Email Address" required />
                <Select name="project" label="I'm interested in..." hash-me clearable>
                    <option v-for="(proj, i) in this.projSel" :disabled="proj.id==='divider'" :key="i" :value="proj.title" :id="proj.id">{{ proj.title }}</option>
                </Select>
                <AppLink class="btn" to="/projects">See Projects</AppLink>
                <Input type="textarea" name="Message" label="Message" required clearable />
                <Upload fileTypes=".ai,.psd,.jpg,.jpeg,.png,.gif,.pdf,.svg,.eps,.ps,.tiff,.heif,.heic" multiple clearable />
                <Button type="submit">Send</Button>
            </form>
        </section>
    </main>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component';
    import Button from "@/components/Button.vue";
    import Select from "@/components/Select.vue";
    import Input from "@/components/Input.vue";
    import Upload from "@/components/Upload.vue";
    import $ from 'jquery'

    @Options({
        name: 'Contact',
        components: {
            Button,
            Select,
            Input,
            Upload,
        },
        data() {
            return {
                custProj: [{
                        "id": "custom",
                        "title": "Custom Engraving"
                    },
                    {
                        "id": "divider",
                        "title": "————————————"
                    }
                ],
            }
        },
        inject: ['projects', 'regex'],
        methods: {
            handleSubmit(form) {
                let formData = new FormData(form),
                    postNav = $(form).attr('route');

                fetch('/',{
                        method: "POST",
                        // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
                        headers: { 'Content-Type': 'multipart/form-data' },
                        body: new URLSearchParams(formData).toString(),
                    })
                    .then(() => {
                        console.log("Form successfully submitted");
                        this.$router.push(postNav);
                    })
                    .catch((error) => alert(error));
            }
        },
        mounted() {
            const func = (f) => this.handleSubmit(f);
            $('form').on('submit', function(e) {
                e.preventDefault();
                func(this);
            })
        },
        computed: {
            projSel() {
                let arr = this.custProj.concat(this.projects);
                return arr;
            }
        }
    })

    export default class Contact extends Vue {}
</script>

<style>

</style>